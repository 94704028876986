import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { H2, H4 } from "../styles/text-styles";
import Button from "./buttons/Button";

export default function PageNotFound() {
  return (
    <Panel>
      <ErrorTitle>404 Error</ErrorTitle>
      <Header>This page must've taken the chairlift.</Header>
      <BodyText>
        If you think this might be a mistake,{" "}
        <Link to="/our-team">
          <span>get in touch.</span>
        </Link>
      </BodyText>
      <ButtonWrapper>
        <Button link="/" title="Back to Home" />
      </ButtonWrapper>
    </Panel>
  );
}

const Panel = styled.div`
  background-color: ${colors.gray2};
  margin: auto;
  border-radius: 12px;
  padding: 24px;
  max-width: 328px;

  ${breakpoints.md} {
    padding: 36px;
  }
`;

const ErrorTitle = styled(H4)`
  margin-bottom: 24px;
  color: ${colors.primary1};
`;

const Header = styled(H2)`
  margin-bottom: 36px;
`;

const BodyText = styled.div`
  margin-bottom: 32px;
  color: ${colors.gray4};
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;

  span {
    color: ${colors.white};

    :hover {
      text-decoration: underline;
    }
  }
`;

const ButtonWrapper = styled.div`
  /* TODO: allow custom button styles */
  /* color: ${colors.primary1}; */
  display: flex;
  justify-content: center;
`;
