import * as React from "react";

import PageNotFound from "../components/PageNotFound";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";

const NotFoundPage = () => (
  <Layout footerHidden>
    <Seo title="404: Not found" />
    <PageNotFound />
  </Layout>
);

export default NotFoundPage;
